@import './Reset';

:root {
  --adalabBlue: #14d9c4;
  --adalabBlueDark: #099d8d;
  --adalabBlueMid: #0bb8a5;
  --adalabBlueTrans: rgba(20,217,196,0.18);
  --adalabBlueTransLight: rgba(20,217,196,0.1);
  --adalabBlueTrans2: rgba(20,217,196,0.30);
  --adalabBlueTrans3: rgba(20,217,196,0.50);
  --adalabRed: rgba(255,0,0,1);
  --adalabRedTrans: rgba(255,0,0,0.33);
  --adalabRedTrans2: rgba(255,0,0,0.6);
  --adalabText: rgba(51, 51, 51, 0.8);
  --adalabFontFamily: Lato, sans-serif;
  --adaGradientSuperUltraLight: linear-gradient(135deg,  var(--adalabBlueTrans) 0%, white 100%);
  --adaGradientSuperLight: linear-gradient(135deg, var(--adalabBlueTrans) 35%, var(--adalabBlueTrans2) 100%);
  --adaGradientLight: linear-gradient(135deg, var(--adalabBlue) 35%, var(--adalabBlueTrans2) 100%);
  --adaGradientDark: linear-gradient(45deg, var(--adalabBlueDark) 0%, var(--adalabBlue) 100%);
}

@keyframes DemoMode {
  0% { background-position: 0% 50% }
  50% { background-position: 100% 50% }
  100% { background-position: 0% 50% }
}

.ada {
  background: linear-gradient(270deg, var(--adalabBlue), var(--adalabRedTrans2));
  background-size: 400% 400%;
  animation: DemoMode 2.5s ease infinite;
}

html {}

body {
  background: linear-gradient(-45deg, var(--adalabBlueTrans2) 0%, white 100%);
  font-family: var(--adalabFontFamily) !important;
  font-weight: lighter !important;
}

a, a:visited {
  &:not(.MuiButtonBase-root) {color: inherit;}
}
.blue, .blue:visited {
  color: var(--adalabBlue)
} 

main {
  min-height: 100vh;
  display: flex !important;
  align-items: center;
  justify-content: center;
  // padding: 2em 0;
}

.MuiCard-root a {
  text-decoration: none;
  color: inherit;
}

.react-datepicker-popper { z-index: 9999; }

.MuiTab-root { max-width: 120px !important; }
.allWhite {
  color: white !important;
  * { color: white !important; }
  &:before { border-color: rgba(255,255,255,0.43) !important; }
  &:after { border-color: white !important; }
}

.modalWithDatepicker {
  .MuiPaper-root, .MuiDialogContent-root {
    overflow: visible !important;
  }
}

.distribute {
  > * + * { margin-left: 1em !important; }
}

.centerTabs { width: 100%; }

.inactive {
  background: var(--adalabRedTrans);
}
.contratada {
  background: var(--adalabBlueTransLight)
}

.MuiListItem-divider:last-child { border: none }

.offerComplete {
  background: var(--adalabRedTrans) !important;
  opacity: .8;
  cursor: inherit;
  a {pointer-events: none;}
  .offerCompleteIcon {
    position: absolute;
    bottom: .5em;
    right: .5em;
    opacity: 0;
    transition: all .25s;
  }
  &:hover{
    .offerCompleteIcon {
      opacity: 1;
    }
  }
}

.form {
  fieldset {
    margin-bottom: 3em;
    max-width: 100%;
  }
}

.align-top {
  .MuiDialog-container {
    align-items: flex-start;
  }
}

$chip-colors: (
  "A1.1 - A1.2": #f94144ff,
  "A1": #f94144ff,
  "A1.2 - A2.1": #f3722cff,
  "A2.1 - A2.2": #f8961eff,
  "A2": #f8961eff,
  "B1.1 - B1.2": #f9c74fff,
  "B1": #f9c74fff,
  "B2.1 - B2.2": #90be6dff,
  "B2.2 - C1.1": #43aa8bff,
  "B2": #43aa8bff,
  "C1.2 - C2.1": #577590ff,
  "C1": #577590ff,
  "C2.1 - C2.2": #4d9de0ff,
  "C2": #4d9de0ff,
);

@each $level, $color in $chip-colors {
  [data-color="#{$level}"] {
    background-color: $color !important;
    font-weight: bold;
  }
}

.MuiTableRow-root[style="height: 10px;"] {
  height: 0px;
}

.DelayedSpinner {
  animation: 2s linear 0.5s makeVisible;
  // visibility: hidden;
}

@keyframes makeVisible {
  0%   { opacity: 0; }
  50% { opacity: 1; }
  100%   { opacity: 0; }
}

.TableRow {
  // transition: background-color 250ms ease;
  &:hover {
    background: var(--adalabBlueTrans) !important;
  }
}